/* -------------- APP STYLING --------------*/

:root {
  --amplify-colors-teal-10: #f5e5d5;
  --amplify-colors-teal-20: #e8d3c2;
  --amplify-colors-teal-40: #d7bba5;
  --amplify-colors-teal-60: #c3a28c;
  --amplify-colors-teal-80: #ee492f;
  --amplify-colors-teal-90: #d63926;
  --amplify-colors-teal-100: #b72f1f;

  --amplify-components-authenticator-modal-background-color: #ee492f;
  --amplify-components-tabs-item-active-color: #1a1a1a;
  --amplify-components-tabs-item-active-border-color: #1a1a1a;
  --amplify-components-tabs-border-width: 4px;
  --amplify-components-tabs-item-focus-color: #1a1a1a;
  --amplify-components-tabs-item-hover-color: #1a1a1a;
  --amplify-components-tabs-item-color: rgb(146, 146, 146);
}

a {
  text-decoration: none;
}

.pageContent {
  margin: 0px 2%;
}

.single-gap {
  margin-top: var(--amplify-components-field-gap);
}

.double-gap {
  margin-top: calc(2 * var(--amplify-components-field-gap));
}

.triple-gap-top-bottom {
  margin-top: calc(3 * var(--amplify-components-field-gap));
  margin-bottom: calc(3 * var(--amplify-components-field-gap));
}

.text-align-center {
  text-align: center;
}

.flex {
  display: flex;
}

.flex-row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

/* -------------- NAV BAR --------------*/

.navbar {
  height: 10vh;
  background-color: var(--amplify-colors-teal-80);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  position: relative;
}

.logo {
  height: 50%;
  margin: 10px;
}

.navbar-home-icon {
  font-size: 2rem;
  color: white;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.navbar-secondary-icon {
  font-size: 1.8rem;
  color: white;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
}

/* -------------- REUSABLE COMPONENTS --------------*/

.button-black,
.button-black:active,
.button-black:focus,
.button-black:hover {
  color: white;
  background-color: #1a1a1a;
}

.card {
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px 0px;
  padding: 15px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

/* -------------- AUTHENTICATION --------------*/

.auth-header {
  text-align: center;
  padding: 20px;
}

.auth-logo {
  width: 50%;
}

.auth-signup-header {
  padding-left: var(--amplify-components-authenticator-form-padding);
  padding-top: var(--amplify-components-authenticator-form-padding);
}

.amplify-flex .amplify-button--primary {
  color: white;
  background-color: #1a1a1a;
}

.amplify-tabs-item:active {
  color: #1a1a1a;
}

/* -------------- RECEIPTS --------------*/

.venue-logo {
  width: 25%;
  max-width: 125px;
  height: 25%;
  margin-right: 15px;
  border-radius: 8px;
}

.venue-logo-small {
  width: 20%;
  max-width: 100px;
  height: 20%;
  margin-right: 15px;
  border-radius: 8px;
}

.venue-info {
  width: 100%;
}

/* -------------- PROFILE --------------*/

.card-profile {
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0px;
  padding: 15px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.card-profile-collapsable-header {
  display: flex;
  justify-content: space-between;
}

.profile-QR-code {
  width: 30%;
  max-width: 125px;
  margin-right: 15px;
}

.profile-QR-code-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.username-text {
  line-height: 2.2rem;
}

/* -------------- DOCUMENT SCREEN --------------*/

.pdf-frame {
  width: 100%;
  height: 60vh;
  border: none;
}